import BaseMenu from "./baseMenu";


export default function ContactsMenu() {
    return <BaseMenu items={[
        {
            to: "/docs/api/contacts/post/block",
            type: "post",
            display: "BLOCK"
        },
        {
            to: "/docs/api/contacts/post/unblock",
            type: "post",
            display: "UNBLOCK"
        },
        {
            to: "/docs/api/contacts/get/contacts",
            type: "get",
            display: "CONTACTS"
        },
        {
            to: "/docs/api/contacts/get/contact",
            type: "get",
            display: "CONTACT"
        },
        {
            to: "/docs/api/contacts/get/blocked",
            type: "get",
            display: "BLOCKED"
        },
        {
            to: "/docs/api/contacts/get/check",
            type: "get",
            display: "CHECK"
        },
        {
            to: "/docs/api/contacts/get/image",
            type: "get",
            display: "IMAGE"
        }
      ]}
    />
}