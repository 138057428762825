import { ICreatingInstance } from "models/creatingInstance.model";
import IInstance from "models/instance.model";
import { IConfigMassive, IMassiveRequest } from "models/massive.models";
import { Itm_messages } from "models/messages.models";
import { IUser } from "models/user.model";
import { createContext } from "react";

export const USER_ANONYMOUS: IUser = {
    id_user: -1,
    admin: 0,
    avatar: null,
    email: "anonymous@anonymous",
    email_notifications: 0,
    name: "Anonymous",
    phone: null,
    createdAt: "@none",
    updatedAt: "@none",
};

/**
 * Método para indicar que no existe el método
 */
const callbackNoDefined = () => {
    // Lanzar error
    throw new Error("This method is not defined!");
}

/**
 * Interfaz para el contexto a crear
 */
export interface IUserContext {
    /**
     * Estado actual de la conexión del usuario
     */
    readonly authState: "unauthorized" | "authorized" | "checking";
    /**
     * Verifica si se encuentra conectado con el servidor
     */
    readonly connected: boolean;
    /**
     * Información del usuario actual
     */
    readonly user: IUser;
    /**
     * Instancias del usuario
     */
    readonly instances: IInstance[];
    /**
     * Instancia en creación
     */
    readonly creatingInstance: ICreatingInstance | null;
    /**
     * Permite iniciar sesión
     * @param email Correo electrónico
     * @param password Contraseña
     * @param remember Recordar al usuario
     */
    SignIn(email: string, password: string, remember?: boolean): Promise<IUser>;

    /**
     * Permite enviar un código de verificación al correo electrónico asociado para crear la cuenta
     * @param email Correo electrónico
     * @param password Contraseña
     */
    SignUp(email: string, password: string, username: string, phone: string): Promise<{ token: string }>;

    /**
     * Permite finalizar el proceso para crear una cuenta en la página
     * @param email Correo electrónico
     * @param code Código de verificación
     */
    SignUpAuthorize(token: string, email: string, code: string): Promise<IUser>;

    /**
     * Permite iniciar sesión por medio del proveedor de Google
     */
    SignInWithGoogle(): Promise<IUser>;

    /**
     * Permite cerrar sesión
     */
    Logout(): Promise<void>;

    /**
     * Permite crear una nueva instancia
     */
    CreateInstance(callbackWhenCreated?: (instance: IInstance) => void): Promise<ICreatingInstance>;

    /**
     * Permite abortar la creación de la instancia
     */
    AbortCreateInstance(): Promise<void>;

    /**
     * Elimina una instancia
     */
    RemoveInstance(id_instance: string): Promise<void>

    /**
     * Actualiza una instancia
     */
    UpdateInstance(id_instance: string, instance: { [key in keyof IInstance]?: IInstance[key] }): Promise<void>;

    /**
     * Inicializa una instancia
     * @param id_instance Identificador de la instancia
     */
    StartInstance(id_instance: string): Promise<void>;

    /**
     * Detiene una instancia
     * @param id_instance Identificador de la instancia
     */
    StopInstance(id_instance: string): Promise<void>;

    /**
     * Cierra sessión de una instancia
     * @param id_instance Identificador de la instancia
     */
    LogoutInstance(id_instance: string): Promise<void>;

    /**
     * Actualiza el token de authorización a otro
     * @param id_instance Identificador de la instancia
     */
    ChangeTokenInstance(id_instance: string): Promise<void>;

    /**
     * Permite eliminar los mensajes de un status en particular de una instancia
     * @param id_instance Identificador de la instancia
     */
    ClearMessagesByStatus(id_instance: string, status: Itm_messages["status"]): Promise<void>

    /**
     * Permite colocar en la cola de envio a los mensajes asociados a un status en particular
     * @param id_instance Identificador de la instnaica
     * @param status Status asociadoa  los mensajes
     */
    RetryMessagesByStatus(id_instance: string, status: Itm_messages["status"]): Promise<void>;

    /**
     * Permite enviar un masivo de mensajes
     * @param id_instance Identificador de la instancia
     * @param massive Datos del masivo a enviar
     */
    SendMassive(id_instance: string, massive: IMassiveRequest, config?: IConfigMassive): Promise<void>;
}

// Exportar contexto
export default createContext<IUserContext>({
    authState: "unauthorized",
    connected: false,
    user: USER_ANONYMOUS,
    instances: [],
    creatingInstance: null,
    SignIn: callbackNoDefined,
    Logout: callbackNoDefined,
    SignInWithGoogle: callbackNoDefined,
    SignUp: callbackNoDefined,
    SignUpAuthorize: callbackNoDefined,
    CreateInstance: callbackNoDefined,
    AbortCreateInstance: callbackNoDefined,
    RemoveInstance: callbackNoDefined,
    UpdateInstance: callbackNoDefined,
    LogoutInstance: callbackNoDefined,
    StartInstance: callbackNoDefined,
    StopInstance: callbackNoDefined,
    ChangeTokenInstance: callbackNoDefined,
    ClearMessagesByStatus: callbackNoDefined,
    RetryMessagesByStatus: callbackNoDefined,
    SendMassive: callbackNoDefined
});