

import { createContext } from "react";
import langES from "../lang/ES.json";

/**
 * Nombre de los lenguajes
 */
export type ILangName = "ES" | "EN" | "FR" | "IT" | "PT" | "TR" | "HI" | "ID" | "RU";

/**
 * Interfaz del contexto del lenguaje
 */
export type ILangContext = typeof langES & {
    lang: ILangName,
    ChangeLanguage(newLanguage: ILangName): void,
}

/**
 * Método no definido
 */
const callbackNoDefined = () => {
    throw new Error("callback is'nt defined");
};

/**
 * Crear contexto 
 */
export default createContext<ILangContext>({
    ...langES,
    lang: "ES",
    ChangeLanguage: callbackNoDefined,
});

