import {
    Box,
    Drawer,
    List, styled,
    Theme, useMediaQuery
} from "@mui/material";
import { FC } from "react";
import ScrollBar from "simplebar-react";
  
  // root component interface
  interface SideNavBarProps {
    showMobileSideBar: boolean;
    closeMobileSideBar: () => void;
    children: React.ReactNode
  }
  
  // custom styled components
  const MainMenu = styled(Box)(({ theme }) => ({
    left: 0,
    width: "300px",
    height: "100%",
    boxShadow: theme.shadows[2],
    transition: "left 0.3s ease",
    zIndex: theme.zIndex.drawer + 11,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("md")]: { right: "3rem" },
    "& .simplebar-track.simplebar-vertical": { width: 7 },
    "& .simplebar-scrollbar:before": {
      background: theme.palette.text.primary,
    },
  }));
  
  // root component
  const DashboardOtherSideBar: FC<SideNavBarProps> = ({
    showMobileSideBar,
    closeMobileSideBar,
    children,
  }) => {
    // const navigate = useNavigate();
  
    // const [active, setActive] = useState("Dashboard");
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  
    // const handleActiveMainMenu = (menuItem: any) => () => {
    //   setActive(menuItem.title);
  
    //   navigate(menuItem.path);
    //   closeMobileSideBar();
    // };
  
    // main menus content
    const mainSideBarContent = (
      <List sx={{ height: "100%", overflow:"hidden" }}>
        <ScrollBar style={{ maxHeight: "100%"}}>
          {children}
        </ScrollBar>
      </List>
    );
  
    // for mobile device
    if (downMd) {
      return (
        <Drawer
          anchor="right"
          open={showMobileSideBar}
          onClose={closeMobileSideBar}
          PaperProps={{ sx: { width: 300 } }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              width: "inherit",
              position: "fixed",
              overflow: "hidden",
              flexDirection: "column",
              boxShadow: (theme) => theme.shadows[1],
              backgroundColor: (theme) => theme.palette.background.paper,
              "& .simplebar-track.simplebar-vertical": { width: 7 },
              "& .simplebar-scrollbar:before": {
                background: (theme) => theme.palette.text.primary,
              },
            }}
          >
            {mainSideBarContent}
          </Box>
        </Drawer>
      );
    }
  
    return <MainMenu>{mainSideBarContent}</MainMenu>;
  };
  
  export default DashboardOtherSideBar;
  