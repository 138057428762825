// import { AuthProvider } from "contexts/JWTAuthContext";
import SettingsProvider from "contexts/SettingsContext";
import TitleContextProvider from "contexts/TitleContext";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar-react/dist/simplebar.min.css";
import App from "./App";
// import "./__fakeApi__";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
      <SettingsProvider>
        <TitleContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </TitleContextProvider>
      </SettingsProvider>
  </StrictMode>
);
