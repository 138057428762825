import BaseMenu from "./baseMenu";


export default function MessageMenu() {
    return <BaseMenu items={[
        {
            to: "/docs/ws/received/messages/chat",
            type: "send",
            display: "CHAT"
        },
        {
            to: "/docs/ws/received/messages/image",
            type: "send",
            display: "IMAGE"
        },
        {
            to: "/docs/ws/received/messages/sticker",
            type: "send",
            display: "STICKER"
        },
        {
            to: "/docs/ws/received/messages/document",
            type: "send",
            display: "DOCUMENT"
        },
        {
            to: "/docs/ws/received/messages/audio",
            type: "send",
            display: "AUDIO"
        },
        {
            to: "/docs/ws/received/messages/voice",
            type: "send",
            display: "VOICE"
        },
        {
            to: "/docs/ws/received/messages/video",
            type: "send",
            display: "VIDEO"
        },
      ]}
    />
}