import BaseMenu from "./baseMenu";

import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import FunctionsIcon from '@mui/icons-material/Functions';
import { useParams } from "react-router-dom";

export default function MessageMenu() {
    const { id_instance } = useParams();

    return <BaseMenu items={[
        {
            to: "/dashboard/instances/show/" + id_instance + "/bot/admin",
            icon: <DisplaySettingsIcon color="primary" />,
            display: "Configuración"
        },
        {
            to: "/dashboard/instances/show/" + id_instance + "/bot/commands",
            icon: <FunctionsIcon color="primary" />,
            display: "Comandos"
        },
        // {
        //     to: "/dashboard/instances/show/" + id_instance + "/bot/schedules",
        //     icon: <BrowseGalleryIcon color="primary" />,
        //     display: "Control Horarios"
        // },
    ]}
    />
}