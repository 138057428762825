
import {
  Box, Button, List, ListItem, styled
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import "./index.css";

function IconSend() {
  return <div className="icon-display" style={{color: "white", backgroundColor: "rgba(160,60,160)"}}>SEND</div>
}

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.palette.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.palette.warning.main};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.palette.primary.main};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${theme.palette.primary[100]};
            color: ${theme.palette.primary.main};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.palette.warning.main};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.palette.warning.main};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);


function Icon(props: {type: "send"}) {
    switch(props.type) {
        case "send": 
            return <IconSend />

        // case "get": 
        //     return <IconGet />

        default:
            return null;
    }
}

export default function BaseMenu(props: { items: {to: string, type: "send", display: string}[] }) {
  // const { closeSidebar } = useContext(SidebarContext);
    
    return (
          <SubMenuWrapper>
            <List component="div">
                {props.items.map(item => (
                    <ListItem component="div" key={item.type + "-" + item.display}>
                        <Button
                            onClick={() => console.log("Close Sidebar")}
                            disableRipple
                            component={RouterLink}
                            to={item.to}
                            startIcon={<Icon type={item.type} />}
                        >
                            {item.display}
                        </Button>
                    </ListItem>
                ))}
            </List>
          </SubMenuWrapper>
    )
}