import { CircularProgress } from "@mui/material";
import LangContext, { ILangContext, ILangName } from "contexts/lang.context";
import React, { useMemo } from "react";

/**
 * Lenguajes importables
 */
const languages: {
    [key in ILangName]: () => Promise<any>
} = {
    EN: () => import("../lang/EN.json"),
    ES: () => import("../lang/ES.json"),
    FR: () => import("../lang/FR.json"),
    HI: () => import("../lang/HI.json"),
    ID: () => import("../lang/ID.json"),
    IT: () => import("../lang/IT.json"),
    PT: () => import("../lang/PT.json"),
    RU: () => import("../lang/RU.json"),
    TR: () => import("../lang/TR.json"),
};

/**
 * 
 * @param data Datos a compartir
 * @returns Returna una función para 
 */
const makeProvider = (data: ILangContext) => (
    function ({
        children
    }: {
        children: React.ReactElement
    }) {
        return (
            <LangContext.Provider value={data}>
                {children}
            </LangContext.Provider>
        )
    }
)

export default function LangProvider({
    children
}: {
    children: React.ReactElement
}) {
    const [lang, setLang] = React.useState<ILangName>("ES");

    /**
     * Permite importar el lenguaje dentro de un componente lazy
     */
    const LoadLangLazy = useMemo(() => {
        return (
            React.lazy(() => {
                return new Promise((resolve, reject) => {
                    try {
                        languages[lang]()
                            .then((json) => {
                                resolve({
                                    default: makeProvider({
                                        ...json,
                                        ChangeLanguage: setLang,
                                        lang: lang,
                                    }) as never
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    }
                    catch (err) {
                        reject(err);
                    }
                });
            })
        );
    }, [lang])

    return (
        <React.Suspense fallback={
            <CircularProgress />
        }>
            <LoadLangLazy>
                {children}
            </LoadLangLazy>
        </React.Suspense>
    )
}
