import ChatIcon from '@mui/icons-material/Chat';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactsIcon from '@mui/icons-material/Contacts';
import DescriptionIcon from '@mui/icons-material/Description';
import HeadsetIcon from '@mui/icons-material/Headset';
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import { ITypeCommand } from "models/server/commands";
import * as React from "react";

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const iconsCommand: { [key in ITypeCommand]: React.ReactNode } = {
  chat: <ChatIcon />,
  image: <ImageIcon />,
  voice: <MicIcon />,
  audio: <HeadsetIcon />,
  video: <VideocamIcon />,
  document: <DescriptionIcon />,
  link: <LinkIcon />,
  contact: <ContactsIcon />,
  location: <LocationOnIcon />,
  vcard: <ContactMailIcon />
}

export const nameCommandType: { [key in ITypeCommand]?: string } = {
  chat: "Chat",
  image: "Imagen",
  voice: "Voz",
  audio: "Audio",
  video: "Video",
  document: "Documento",
  link: "Enlace",
  contact: "Contacto",
  // location: "Ubicación",
  // vcard: "Vcard"
}