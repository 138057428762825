import environment from "environment/environment";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

export default new class FirebaseService {

    private app: FirebaseApp;
    private auth: Auth;
    
    /**
     * Constructor del servicio
     */
    constructor() {
        this.app = initializeApp(environment.firebaseConfig);
        this.auth = getAuth(this.app);
    }

    /**
     * Permite iniciar sesión por medio del proveedor de google
     * @returns Token de autorización
     */
    public SignInWithGoogle(): Promise<{uid: string, token: string}> {
        return new Promise((resolve, reject) => {
            try {
                const provider = new GoogleAuthProvider();

                // Inicializar Inicio de sesión
                signInWithPopup(this.auth, provider)
                    .then((userCredential) => {
                        userCredential.user.getIdToken()
                            .then((token) => {
                                resolve({
                                    uid: userCredential.user.uid,
                                    token: token
                                })
                            })
                            .catch(reject);
                    })
                    .catch(reject);
            }
            catch(err) {
                reject(err);
            }
        })
    }
    
}