declare module "@mui/material/styles" {
  interface PaletteColor {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    red: string;
    purple: string;
    yellow: string;
  }
}

// const themeColors = {
//   primary: '#5569ff',
//   secondary: '#6E759F',
//   success: '#57CA22',
//   warning: '#FFA319',
//   error: '#FF1943',
//   info: '#33C2FF',
//   black: '#223354',
//   white: '#ffffff',
//   primaryAlt: '#000C57'
// };

export const primary = {
  100: "#D0E5FE",
  200: "#A7E7FD",
  300: "#7AD2FA",
  400: "#59BCF5",
  500: "#2499EF",
  main: "#2d5698",
  light: "#D3F5FE",
  dark: "#1A77CD",
  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
};

export const secondary = {
  100: "#F9F9F9",
  200: "#ECEFF5",
  300: "#E5EAF2", // outline or border
  400: "#94A4C4", // text muted
  500: "#1d2438", // main text
  main: "#4e008f", // main text
  light: "#F9F9F9",
  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
};

export const error = {
  main: "#FF1943",
};

export const success = {
  main: "#57CA22",
};

export const warning = {
  main: "#FFA319",
  dark: "#F49714",
};

export const info = {
  main: "#33C2FF",
};

export const text = {
  primary: secondary[500],
  secondary: secondary[400],
  disabled: secondary[400],
};
