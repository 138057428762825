import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionSummary, Box,
  CardHeader,
  Divider,
  useTheme
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import React from "react";
import { useLocation } from "react-router";
import MessageMenu from './menu';

const MenuWrapper = Box;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

// styled(Box)(
//   ({ theme }) => `
//   .MuiList-root {
//     padding: ${theme.spacing(1)};

//     & > .MuiList-root {
//       padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
//     }
//   }

//     .MuiListSubheader-root {
//       text-transform: uppercase;
//       font-weight: bold;
//       font-size: ${theme.typography.pxToRem(12)};
//       color: ${theme.colors.alpha.trueWhite[50]};
//       padding: ${theme.spacing(0, 2.5)};
//       line-height: 1.4;
//     }
// `
// );

function SidebarMenu() {
  const theme = useTheme();
  const location = useLocation();
  const [_ignored, _docs, type, _method] = location.pathname.split("/");
  let defaultValue: number = 0;

  switch(type) {
    case "messages":
      defaultValue = 0;
      break;

    case "instance":
      defaultValue = 1;
      break;

    case "chats":
      defaultValue = 2;
      break;

    case "contacts":
      defaultValue = 3;
      break;

    case "groups":
      defaultValue = 4;
      break;

    case "media":
      defaultValue = 5;
      break;
  }
  
  const [open, setOpen] = React.useState<number>(defaultValue);

  const itemsMenu = [
    {
      label: "Messages",
      menu: <MessageMenu/>
    },
    // {
    //   label: "Instance",
    //   menu: <InstanceMenu/>
    // },
    // {
    //   label: "Chats",
    //   menu: <ChatsMenu/>
    // },
    // {
    //   label: "Contacts",
    //   menu: <ContactsMenu/>
    // },
    // {
    //   label: "Groups",
    //   menu: <GroupsMenu/>
    // },
    // {
    //   label: "Media",
    //   menu: <MediaMenu/>
    // }
  ]
  

  return (
    <Box style={{width: "100%"}}>
      <CardHeader title="BOT ADMIN"/>
      <Divider/>
      <MenuWrapper>
        {itemsMenu.map((item, index) => (
          <Accordion 
            key={String(index)}
            expanded={index === open}
            style={{background:"inherit"}}
          >
            <AccordionSummary
              style={index === defaultValue ? {backgroundColor:  theme.palette.primary[100], color: theme.palette.primary.main} : undefined}
              expandIcon={<ExpandMoreIcon style={{color: theme.palette.primary.main}}/>}
              onClick={() => {
                if(index === open) setOpen(-1);
                else setOpen(index)
              }}
            >
              {item.label}
            </AccordionSummary>
            <AccordionDetails>
              {item.menu}
            </AccordionDetails>
          </Accordion>
        ))}
      </MenuWrapper>
    </Box>
  );
}

export default SidebarMenu;
