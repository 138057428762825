import { Grid, useTheme } from '@mui/material';
import React, { FC, ReactNode } from "react";

// import Header from '../Header';
import AuthGuard from 'components/authentication/AuthGuard';
import useLang from "hooks/useLang";
import useUser from "hooks/useUser.hook";
import { Outlet, useParams } from 'react-router-dom';
import DashboardLayout from '../DashboardLayout';
import Sidebar from './SidebarMenu';

interface SidebarLayoutProps {
  children?: ReactNode;
}

function IsAuthorized(props: { children: React.ReactNode, other: React.ReactNode }) {
  const userAccount = useUser();
  // const authorized = userAccount.isAuthorized;

  // if(authorized) {
  return (
    <AuthGuard>
      {/* <DashboardLayout otherSidebar={props.other}> */}
      <DashboardLayout>
        {props.children}
      </DashboardLayout>
    </AuthGuard>
  )
  // }
  // return <>{props.children}</>
}

const SidebarLayoutBot: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const lang = useLang();
  const { id_instance } = useParams();

  return (
    <IsAuthorized other={<Sidebar />}>
      <Grid container spacing={2} p={1} pb={12}>
        {/* <Grid item xs={12}>
          <Card>
            <Box px={1} py={2} display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" gap="10px">
                <Button
                  style={{ marginLeft: "10px" }}
                  variant="outlined"
                  color="primary"
                  startIcon={<Assignment />}
                >
                  {lang.pages.dashboard.instances.show.bot.USEDATADEFAULT}
                </Button>
              </Box>
              <Box component={NavLink} to={"/dashboard/instances/show/" + id_instance}>
                <Tooltip title={lang.pages.dashboard.instances.show.bot.GOBACK}>
                  <Button startIcon={<KeyboardBackspace />}>
                    {lang.pages.dashboard.instances.show.bot.BACK}
                  </Button>
                </Tooltip>
              </Box>
            </Box>

          </Card>
        </Grid> */}
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </IsAuthorized>
  );
};

export default SidebarLayoutBot;
