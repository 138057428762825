import BaseMenu from "./baseMenu";


export default function MessageMenu() {
    return <BaseMenu items={[
        {
            to: "/docs/api/messages/get/text",
            type: "post",
            display: "TEXT"
        },
        {
            to: "/docs/api/messages/get/contact",
            type: "post",
            display: "CONTACT"
        },
        {
            to: "/docs/api/messages/get/image",
            type: "post",
            display: "IMAGE"
        },
        {
            to: "/docs/api/messages/get/sticker",
            type: "post",
            display: "STICKER"
        },
        {
            to: "/docs/api/messages/get/document",
            type: "post",
            display: "DOCUMENT"
        },
        {
            to: "/docs/api/messages/get/audio",
            type: "post",
            display: "AUDIO"
        },
        {
            to: "/docs/api/messages/get/voice",
            type: "post",
            display: "VOICE"
        },
        {
            to: "/docs/api/messages/get/video",
            type: "post",
            display: "VIDEO"
        },
        {
            to: "/docs/api/messages/post/messages",
            type: "get",
            display: "MESSAGES"
        },
        {
            to: "/docs/api/messages/post/statistics",
            type: "get",
            display: "STATISTICS"
        }
    ]}
    />
}