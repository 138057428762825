import { Badge, Box, ButtonBase, Divider, styled } from "@mui/material";
import FlexBox from "components/FlexBox";
import { H6, Small, Tiny } from "components/Typography";
import UkoAvatar from "components/UkoAvatar";
// import useAuth from "hooks/useAuth";
import PersonIcon from '@mui/icons-material/Person';
import useLang from "hooks/useLang";
import useUser from "hooks/useUser.hook";
import { FC, Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout";

// styled components
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

const ProfilePopover: FC = () => {
  const userAccount = useUser();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const lang = useLang();

  const handleMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const isAuthorized = userAccount.authState == "authorized";

  return (
    <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        {userAccount.user.avatar &&
          <Badge
            overlap="circular"
            variant="dot"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{
              "& .MuiBadge-badge": {
                width: 11,
                height: 11,
                right: "7%",
                borderRadius: "50%",
                border: "2px solid #fff",
                backgroundColor: "success.main",
              },
            }}
          >
            <UkoAvatar
              src={userAccount.user.avatar}
              sx={{ width: 30, height: 30, ml: 1 }}
            />
          </Badge>
        }
        {!userAccount.user.avatar &&
          <UkoAvatar sx={{ width: 30, height: 30, ml: 1 }}>
            <PersonIcon />
          </UkoAvatar>
        }
      </ButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center">
            {userAccount.user.avatar &&
              <UkoAvatar
                src={userAccount.user.avatar || "/static/avatar/001-man.svg"}
                sx={{ width: 35, height: 35 }}
              />
            }
            {!userAccount.user.avatar &&
              <UkoAvatar sx={{ width: 30, height: 30, ml: 1 }}>
                <PersonIcon />
              </UkoAvatar>
            }

            <Box ml={1}>
              <H6>{userAccount.user.name}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {userAccount.user.email}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>


          {isAuthorized &&
            <>
              {/* <StyledSmall
                onClick={() => handleMenuItem("/dashboard/user-profile")}
              >
                Establecer estado
              </StyledSmall> */}

              <StyledSmall
                onClick={() => handleMenuItem("/dashboard/user-profile")}
              >
                {lang.profileMenu.profile}
              </StyledSmall>

              <Divider sx={{ my: 1 }} />
              <StyledSmall
                onClick={() => {
                  userAccount.Logout()
                    .then(() => {
                      toast.error("Has cerrado sesión");
                      navigate("/login");
                    })
                    .catch((err) => console.log(err))
                }}
              >
                {lang.profileMenu.close}
              </StyledSmall>
            </>
          }
          {!isAuthorized &&
            <StyledSmall
              onClick={() => {
                navigate("/login")
              }}
            >
              Iniciar Sesión
            </StyledSmall>
          }
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
