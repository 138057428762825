import {
  GridView as UserGridIcon, LibraryAdd, Login as LoginIcon
} from "@mui/icons-material";
import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from "./Dashboard";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginIcon,
  LibraryAdd,
  SessionsIcon,
  UserGridIcon,
  DashboardIcon,
  UserProfileIcon,
  UserManagementIcon,
  ArticleIcon
};
