import useLang from "hooks/useLang";
import Icons from "icons/sidebar";

function useIndex() {
  const lang = useLang();

  return [
    {
      title: lang.headers.dashboard,
      Icon: Icons.DashboardIcon,
      path: "/dashboard/statistics",
    },
    {
      title: lang.headers.profile,
      Icon: Icons.UserProfileIcon,
      path: "/dashboard/user-profile",
    },
    {
      title: lang.headers.instances,
      Icon: Icons.UserManagementIcon,
      path: "/dashboard/instances",
    },
    {
      title: lang.headers.docs,
      Icon: Icons.ArticleIcon,
      path: "/docs"
    }
    // {
    //   title: "Cerrar Sesión",
    //   Icon: Icons.LoginIcon,
    //   path: "/login",
    // },
    // {
    //   title: "Crear Cuenta",
    //   Icon: Icons.SessionsIcon,
    //   path: "/Register",
    // },
  ]
};

export default useIndex;
