import userContext from "contexts/user.context";
import { useContext } from "react";

/**
 * Hook para obtener los métodos y datos del usuario
 * @returns Datos para manejar el usuario
 */
export default function useUser() {
    // Extraer contexto y retornar valor
    return useContext(userContext);
}