import { Box, Card, Container, styled, Typography, Zoom } from "@mui/material";
import Fab from '@mui/material/Fab';
import useUser from "hooks/useUser.hook";
import React, { FC, Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardOtherSideBar from "./DashboardOtherSidebar";
import DashboardSidebar from "./DashboardSideBar";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ScrollBar from "simplebar-react";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  width: `calc(100%)`,
  maxWidth: 1600,
  margin: "auto",
  // paddingLeft: "0.5rem",
  // paddingRight: "0.5rem",
  // paddingLeft: 80,
  // [theme.breakpoints.down("md")]: {
  //   width: "100%",  
  //   // boxSizing: "border-box",
  //   // marginLeft: 0,
  //   marginBottom: "3rem",
  // },
}));

interface props {
  children?: React.ReactNode,
  otherSidebar?: React.ReactNode
}

const DashboardLayout: FC<props> = ({ children, otherSidebar }) => {
  const userAccount = useUser();
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);
  const [showMobileOtherSideBar, setShowMobileOtherSideBar] = useState(false);

  return (
    <Fragment>
      <Box boxSizing="border-box" display="flex" width="100%" height="100vh" style={{ boxSizing: "border-box" }}>
        <DashboardSidebar
          showMobileSideBar={showMobileSideBar}
          closeMobileSideBar={() => setShowMobileSideBar(false)}
        />

        <div style={{ display: "flex", justifyContent: "stretch", flexDirection: "column", width: "100%", boxSizing: "border-box" }}>
          <ScrollBar style={{ boxSizing: "border-box", width: "100%", height: "100%" }}>
            <Wrapper>
              <DashboardNavbar
                setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
              />

              <Container>
                {children || <Outlet />}
              </Container>
            </Wrapper>
          </ScrollBar>

          {/* {userAccount.masivos.length > 0 &&
            <div style={{ display: "flex", position: "absolute", right: "20px", bottom: "20px", gap: "10px", minWidth: "240px" }}>
              {userAccount.masivos.map(masivo => (
                <React.Fragment key={masivo.id_masivo}>
                  {masivo.show &&
                    <Card className="smooting" sx={{ boxShadow: "3px 3px 10px 5px rgba(0,0,0,0.2)", width: "100%" }}>
                      <Box p={1} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Masivo en progreso ({masivo.msgTotal})</Typography>
                        <IconButton onClick={() => userAccount.HiddeMasivo(masivo.id_masivo)}>
                          <VisibilityOff fontSize="small" />
                        </IconButton>
                      </Box>
                      <Divider />
                      <Box p={1} display="flex" flexDirection="column" gap={1}>
                        <div>
                          <Typography color="primary">{masivo.msgSended} Enviados</Typography>
                          <LinearProgress sx={{ width: "100%" }} variant="determinate" color="primary" value={masivo.msgSended / masivo.msgTotal * 100} />
                        </div>
                        <div>
                          <Typography color="error">{masivo.msgError} No enviados</Typography>
                          <LinearProgress sx={{ width: "100%" }} variant="determinate" color="error" value={masivo.msgError / masivo.msgTotal * 100} />
                        </div>
                      </Box>
                    </Card>
                  }
                </React.Fragment>
              ))}
            </div>
          } */}
        </div>

        {otherSidebar &&
          <>
            <DashboardOtherSideBar
              showMobileSideBar={showMobileOtherSideBar}
              closeMobileSideBar={() => setShowMobileOtherSideBar(false)}
            >
              {otherSidebar}
            </DashboardOtherSideBar>

            <Zoom
              in={!showMobileOtherSideBar}
              timeout={1000}
              style={{
                transitionDelay: `${!showMobileOtherSideBar ? 1000 : 0}ms`,
                position: "fixed",
                right: "20px",
                bottom: "20px",
                zIndex: 100000,
              }}
              unmountOnExit
            >
              <Fab sx={{
                display: {
                  md: "none",
                  xs: "block"
                }
              }} aria-label={showMobileOtherSideBar ? "Cerrar" : "Abrir"} color="primary" onClick={() => setShowMobileOtherSideBar((state) => !state)}>
                {showMobileOtherSideBar ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
              </Fab>
            </Zoom>
          </>
        }
      </Box>
      {/* 
      <Backdrop
        sx={{ width: "100%", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000000 }}
        open={userAccount.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <Card component={Box} display="block" position="fixed" bottom={0} right={0} px={3}>
        <Typography variant="subtitle2" color="primary" sx={{ fontVariant: "small-caps" }}>gpt solutions <small>v1.3.0</small></Typography>
      </Card>
    </Fragment>
  );
};

export default DashboardLayout;
