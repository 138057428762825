import BaseMenu from "./baseMenu";


export default function GroupsMenu() {
    return <BaseMenu items={[
        {
            to: "/docs/api/groups/get/groups",
            type: "get",
            display: "GROUPS"
        },
        {
            to: "/docs/api/groups/get/ids",
            type: "get",
            display: "IDS"
        },
        {
            to: "/docs/api/groups/get/group",
            type: "get",
            display: "GROUP"
        }
      ]}
    />
}