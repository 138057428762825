export default {
  // server: process.env.NODE_ENV === "production" ? "https://api.gptbotsolutions.com/" : "http://localhost:9007/",
  // websocket: process.env.NODE_ENV === "production" ? "wss://api.gptbotsolutions.com/" : "ws://localhost:9007/",
  // server: "https://api.gptbotsolutions.com/",
  // websocket: "wss://api.gptbotsolutions.com/",
  ...(process.env.NODE_ENV === "production"
    ? {
        server: "https://api.gptbotsolutions.com/",
        websocket: "wss://api.gptbotsolutions.com/",
      }
    : {
        // server: "http://localhost:9007/",
        // websocket: "ws://localhost:9007/",
        server: "http://localhost:9600/",
        websocket: "ws://localhost:9600/",
      }),
  // server: "http://localhost:9007/",
  // websocket: "ws://localhost:9007/",
  // server: "http://ee15-190-103-30-1.ngrok-free.app/",
  // websocket: "ws://ee15-190-103-30-1.ngrok-free.app/",
  firebaseConfig: {
    apiKey: "AIzaSyDQfWh7G42Xjvf9zD6whTxoZ7RCWB2bjbM",
    authDomain: "thomasultra-169de.firebaseapp.com",
    projectId: "thomasultra-169de",
    storageBucket: "thomasultra-169de.appspot.com",
    messagingSenderId: "198003326801",
    appId: "1:198003326801:web:e0140d69080be66c961d70",
  },
};
