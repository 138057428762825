import {
  Box,
  Button,
  Drawer,
  ListItemButton,
  styled,
  Theme,
  Tooltip, useMediaQuery
} from "@mui/material";
import { FC, useState } from "react";
import { Link, NavLink as RouterLink, useNavigate } from "react-router-dom";
import ScrollBar from "simplebar-react";
import useTopMenuList from "./topMenuList";

import WifiIcon from '@mui/icons-material/Wifi';
import useLang from "hooks/useLang";
import useUser from "hooks/useUser.hook";
import Swal from "sweetalert2";

// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: 80,
  height: "100%",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: 100,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: { left: -80 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginBottom: "1rem",
  justifyContent: "center",
  "&:hover": { backgroundColor: "transparent" },

  ".MuiSvgIcon-root": {
    color: theme.palette.secondary[400]
  },
  "&.active": {
    ".MuiSvgIcon-root": {
      color: theme.palette.primary.main
    }
  }
})) as any as typeof ListItemButton;

function ConnectedComponent() {
  const userAccount = useUser();
  const lang = useLang();

  function onClick() {
    Swal.fire({
      icon: "info",
      title: lang.network.infotitle,
      text: lang.network.description,
      confirmButtonText: lang.network.ok
    })
  }

  if (userAccount.connected) {
    return (
      <Tooltip title={lang.network.on}>
        <Button onClick={onClick}>
          <WifiIcon color="primary" />
        </Button>
      </Tooltip>
    )
  }
  return (
    <Tooltip title={lang.network.off}>
      <Button onClick={onClick}>
        <WifiIcon color="error" />
      </Button>
    </Tooltip>
  )
}

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const topMenuList = useTopMenuList();
  const navigate = useNavigate();

  const [active, setActive] = useState("Dashboard");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleActiveMainMenu = (menuItem: any) => () => {
    // setActive(menuItem.title);
    console.log(menuItem)
    navigate(menuItem.path);
    closeMobileSideBar();
  };



  // main menus content
  const mainSideBarContent = (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", boxSizing: "border-box" }}>
      {/* <StyledListItemButton> */}
      <Button>
        <Link to="/home">
          <img src="/static/logo/logo.png" alt="UKO Logo" width={31} />
        </Link>
      </Button>
      {/* </StyledListItemButton> */}

      <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
        {topMenuList.map((nav, index) => (
          <Tooltip title={nav.title} placement="right" key={index}>
            <StyledListItemButton

              disableRipple
              component={RouterLink}
              to={nav.path}
            >
              <nav.Icon />
            </StyledListItemButton>
          </Tooltip>
        ))}
      </ScrollBar>

      <ConnectedComponent />
    </Box>
  );

  // for mobile device
  if (downMd) {
    return (
      <Drawer
        anchor="left"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: 80 } }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "inherit",
            position: "fixed",
            overflow: "hidden",
            flexDirection: "column",
            boxShadow: (theme) => theme.shadows[1],
            backgroundColor: (theme) => theme.palette.background.paper,
            "& .simplebar-track.simplebar-vertical": { width: 7 },
            "& .simplebar-scrollbar:before": {
              background: (theme) => theme.palette.text.primary,
            },


          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

export default DashboardSideBar;
