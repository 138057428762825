import { Box, IconButton, MenuItem, Popover, styled } from "@mui/material";
import { H6 } from "components/Typography";
import { ILangName } from "contexts/lang.context";
import useLang from "hooks/useLang";
import useUser from "hooks/useUser.hook";
import { useRef, useState } from "react";

// dummy language options
const languageOptions: {
  [key in ILangName]: { icon: string; label: string };
} = {
  EN: {
    icon: "/static/flags/en.png",
    label: "English",
  },
  ES: {
    icon: "/static/flags/es.png",
    label: "Español",
  },
  FR: {
    icon: "/static/flags/fr.png",
    label: "Français",
  },
  IT: {
    icon: "/static/flags/it.png",
    label: "Italiano",
  },
  // JA: {
  //   icon: "/static/flags/ja.png",
  //   label: "日本",
  // },
  PT: {
    icon: "/static/flags/pt.png",
    label: "Português",
  },
  TR: {
    icon: "/static/flags/tr.png",
    label: "Türkçe",
  },
  HI: {
    icon: "/static/flags/hi.png",
    label: "हिंदी",
  },
  ID: {
    icon: "/static/flags/id.png",
    label: "Indonesia",
  },
  RU: {
    icon: "/static/flags/ru.png",
    label: "Русский",
  },


  //   hi: {
  //     icon: '/static/flags/in.png',
  //     label: 'Hindi',
  //   },
};

// custom styled components
const IconWrapper = styled(Box)(() => ({
  display: "flex",
  // height: 20,
  // width: 20,
  "& img": {
    width: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  },
}));

const ItemWrapper = styled(Box)(() => ({
  display: "flex",
  "& img": { width: "100%" },
}));

const LanguagePopover = (props: { width?: string | number }) => {
  const lang = useLang();
  const userAccount = useUser();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (language: ILangName) => {
    lang.ChangeLanguage(language)
    setOpen(false);
  };

  const selectedLanguage = languageOptions[lang.lang];

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <IconWrapper>
          <img alt={selectedLanguage.label} src={selectedLanguage.icon} style={{ width: props.width ?? "20px", height: props.width ?? "20px" }} />
        </IconWrapper>
      </IconButton>
      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{ sx: { width: 150, padding: "0.5rem 0" } }}
      >
        {Object.keys(languageOptions).map((language: ILangName) => (
          <MenuItem
            key={languageOptions[language].label}
            onClick={() => handleChangeLanguage(language)}
            selected={language === lang.lang}
          >
            <ItemWrapper>
              <H6 fontWeight={600} ml={1}>
                <img src={languageOptions[language].icon} style={{ width: "20px" }} /> {languageOptions[language].label}
              </H6>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
