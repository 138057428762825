import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import LangComponent from "providers/lang.provider";
import UserProvider from "providers/user.provider";
import { FC } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import routes from "./routes";
import { ukoTheme } from "./theme";

if (process.env.NODE_ENV === "production") {
  console.log(function () { })
  console.error(function () { })
  console.warn(function () { })
}

const App: FC = () => {
  const navigate = useNavigate();
  const allPages = useRoutes(routes);

  // App theme
  const appTheme = ukoTheme();

  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  console.log("Here app");

  return (
    <UserProvider>
      <LangComponent>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={appTheme}>
            <SnackbarProvider>
              <CssBaseline />
              <Toaster toastOptions={toasterOptions} />
              {allPages}
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LangComponent>
    </UserProvider>
  );
};

export default App;
