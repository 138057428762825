import axios from "axios";
import userContext, { IUserContext, USER_ANONYMOUS } from "contexts/user.context";
import environment from "environment/environment";
import IResponseAuthSignin from "models/api/auth/signin.model";
import { ICreatingInstance } from "models/creatingInstance.model";
import IInstance from "models/instance.model";
import { IConfigMassive, IMassiveRequest } from "models/massive.models";
import { Itm_messages } from "models/messages.models";
import { IUser } from "models/user.model";
import { useSnackbar } from "notistack";
import { ReactNode, useEffect, useRef, useState } from "react";
import firebaseService from "services/firebase.service";
import SocketIO, { Socket } from "socket.io-client";


export default function UserProvider({
    children
}: {
    children: ReactNode
}) {
    const [authState, setAuthState] = useState<IUserContext["authState"]>("checking");
    const [instances, setInstances] = useState<IInstance[]>([]);
    const [authorization, setAuthorization] = useState<string | null>(null);
    const [user, setUser] = useState<IUser>(USER_ANONYMOUS);
    const [creatingInstance, setCreatingInstance] = useState<ICreatingInstance | null>(null);
    const __connectionRef = useRef<Socket | null>(null);
    const __callbackCreatedInstance = useRef<{
        id_instance: string,
        callback: (instance: IInstance) => void
    }[]>([]);

    const { enqueueSnackbar } = useSnackbar();

    // Verificar si el usuario se encuentra logueado, en caso de estarlo... realizar 
    // solicitud http para obtener información
    useEffect(() => {
        const authorization = localStorage.getItem("@authorization");

        // Validar si existe el token guardado
        if (authorization) {
            // const controler = new AbortController();

            // axios.get<IResponseAuthSignin>(new URL("/auth/signin", environment.server).href, {
            //     signal: controler.signal,
            //     headers: {
            //         Authorization: authorization
            //     }
            // })
            //     .then((response) => {
            //         AuthorizeLogin(response.data);
            //     })
            //     .catch((err) => {
            //         console.error(err);
            //     });

            // // Retornar callback de limpieza
            // return () => controler.abort();
            setAuthorization(authorization);
        }
        else {
            setAuthState("unauthorized");
        }
    }, []);

    // Inicia la conexión del socket.io
    useEffect(() => {
        try {
            if (authorization) {
                const socket = __connectionRef.current = SocketIO(environment.server, { path: "/syncronize/socket" });

                setAuthState("checking");
                console.log("@@ >> INICIALIZANDO CONEXIÓN SOCKET");

                socket.on("connect", () => {
                    console.log("Conexión establecida con el servidor...");

                    socket.emit("authenticate", authorization, (err: string | undefined, response: IResponseAuthSignin) => {
                        console.log("@@ >> RESPUESTA AUTENTICACION ", err);
                        if (err) {
                            Logout();
                        }
                        else {
                            AuthorizeLogin(response);
                        }
                    });
                });

                socket.on("disconnect", () => {
                    __connectionRef.current = null;
                    console.log("Desconectado...");
                });

                socket.on("create-instance-changed", (change: { [key in keyof ICreatingInstance]?: ICreatingInstance[key] }) => {
                    setCreatingInstance((prevCreating) => {
                        if (prevCreating) {
                            return {
                                ...prevCreating,
                                ...change
                            };
                        }
                    });
                });

                socket.on("instance-created", (instance: IInstance) => {
                    setInstances((prevInstances) => {
                        // Validar que no ya no exista en los registros
                        if (prevInstances.every(instanceItem => instanceItem.id_instance !== instance.id_instance)) {
                            // Ejecutar todos los callbacks sin resolver y eliminarlos
                            __callbackCreatedInstance.current.forEach((callbackItem) => {
                                try {
                                    if (callbackItem.id_instance === instance.id_instance) {
                                        callbackItem.callback(instance);
                                    }
                                }
                                catch (err) {
                                    console.error(err);
                                }
                            });
                            __callbackCreatedInstance.current = __callbackCreatedInstance.current.filter(instanceItem => instanceItem.id_instance !== instance.id_instance);
                            return [
                                ...prevInstances,
                                instance
                            ];
                        }
                    })
                });

                socket.on("instance-removed", (id_instance: string) => {
                    setInstances((prevInstances) => {
                        return prevInstances.filter(instance => instance.id_instance !== id_instance);
                    });
                });

                socket.on("instance-changed", (changes: { id_instance: string } & { [key in keyof IInstance]?: IInstance[key] }) => {
                    // Actualizar registro de una instancia en particular
                    setInstances(instances => {
                        return instances.map(instanceItem => {
                            return instanceItem.id_instance !== changes.id_instance ? instanceItem : {
                                ...instanceItem,
                                ...changes
                            };
                        });
                    })
                });

                // socket.on("massive-created", (massive: IMassiveInfo) => {
                //     setInstances(instances => (
                //         instances.map(instanceItem => (instanceItem.id_instance === massive.id_instance ? ({
                //             ...instanceItem,
                //             massives: [
                //                 ...instanceItem.massives,
                //                 massive
                //             ]
                //         }) : instanceItem))
                //     ));
                // })

                // socket.on("massive-changed", (massiveChanges: { [key in keyof IMassiveInfo]?: IMassiveInfo[key] } & { id_mass_messages: number, id_instance: string }) => {
                //     setInstances(instances => (
                //         instances.map(instanceItem => (instanceItem.id_instance === massiveChanges.id_instance ? ({
                //             ...instanceItem,
                //             massives: instanceItem.massives.map(massiveItem => (massiveItem.id_mass_messages === massiveChanges.id_mass_messages ? {
                //                 ...massiveItem,
                //                 ...massiveChanges
                //             } : massiveItem))
                //         }) : instanceItem))
                //     ));
                // });

                // socket.on("massive-removed", (id_instance: string, id_mass_messages: number) => {
                //     setInstances(instances => (
                //         instances.map(instanceItem => (instanceItem.id_instance === id_instance ? ({
                //             ...instanceItem,
                //             massives: instanceItem.massives.filter(massiveItem => (massiveItem.id_mass_messages !== id_mass_messages))
                //         }) : instanceItem))
                //     ));
                // });

                return () => {
                    console.log("@@ >> CERRANDO CONEXION")
                    // Cerrar la conexión
                    socket.close();
                }
            }
        }
        catch (err) {
            console.error(err);
        }
    }, [authorization]);

    /**
     * Permite obtener la conexión socketIO
     * @returns Promesa para obtener la conexión
     */
    const getConnection = () => {
        return new Promise<Socket>((resolve, reject) => {
            try {
                if (__connectionRef.current) {
                    // Retornar conexión
                    resolve(__connectionRef.current);
                }
                else throw new Error("No está connectado al servidor");
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Permite realizar operaciones comunes al iniciar sesión
     * @param auth Respuesta del servidor para iniciar sesión
     */
    const AuthorizeLogin = (auth: IResponseAuthSignin) => {
        localStorage.setItem("@authorization", auth.authorization);
        setAuthorization(auth.authorization);
        setInstances(auth.instances);
        setUser(auth.user);
        setAuthState("authorized");
    }

    /**
     * Permite iniciar sesión
     * @param email Correco electrónico
     * @param password Contraseña
     * @returns Promesa
     */
    const SignIn = (email: string, password: string) => {
        return new Promise<IUser>((resolve, reject) => {
            try {
                axios.post<IResponseAuthSignin>(new URL("/auth/signin", environment.server).href, {
                    email,
                    password
                })
                    .then((response) => {
                        AuthorizeLogin(response.data);
                        resolve(response.data.user);
                    })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        });
    };

    /**
     * Permite iniciar sesión por medio de google
     * @returns Promesa
     */
    const SignInWithGoogle = () => {
        return new Promise<IUser>((resolve, reject) => {
            try {
                firebaseService.SignInWithGoogle()
                    .then(credentials => {
                        axios.post<IResponseAuthSignin>(new URL("/auth/signin", environment.server).href, {
                            credentials
                        })
                            .then((response) => {
                                AuthorizeLogin(response.data);
                                resolve(response.data.user);
                            })
                            .catch(reject);
                    })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Permite iniciar el proceso de crear una cuenta
     * @param email Correo electrónico
     * @param password Contraseña
     * @returns Promesa que se completa cuando el servidor haya confirmado que ha enviado el código de verificación
     */
    const SignUp = (email: string, password: string, username: string, phone: string) => {
        return new Promise<{ token: string }>((resolve, reject) => {
            try {
                axios.post(new URL("/auth/signup", environment.server).href, {
                    email,
                    password,
                    username,
                    phone
                })
                    .then((res) => {
                        resolve({ token: res.data.token });
                    })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        });
    };

    /**
     * Permite finalizar el proceso para crear la cuenta
     * @param email Correo asociado
     * @param code Código de verificación
     * @returns Promesa que resuelve los datos del usuario luego de haber sido verificado por el servidor
     */
    const SignUpAuthorize = (token: string, email: string, code: string) => {
        return new Promise<IUser>((resolve, reject) => {
            try {
                axios.post<IResponseAuthSignin>(new URL("/auth/signup/verify", environment.server).href, {
                    email,
                    code
                }, {
                    headers: {
                        Authorization: token
                    }
                })
                    .then((res) => {
                        enqueueSnackbar("La cuenta ha sido creada con éxito!", { variant: "success" });
                        AuthorizeLogin(res.data);
                        resolve(res.data.user);
                    })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Permite cerrar sesión
     * @returns Promesa
     */
    const Logout = () => {
        return new Promise<void>((resolve, reject) => {
            try {
                setAuthState("unauthorized");
                resolve();
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Permite inicializar el proceso para crear una nueva instancia
     * @returns Promesa
     */
    const CreateInstance = (callbackWhenCreated?: (instance: IInstance) => void) => {
        return new Promise<ICreatingInstance>((resolve, reject) => {
            try {
                getConnection()
                    .then((connection) => {
                        // Crear instancia
                        connection.emit("create-instance", (err: string | undefined, creatingInstance: ICreatingInstance) => {
                            if (err) {
                                reject(new Error(err));
                            }
                            else {
                                if (callbackWhenCreated) {
                                    __callbackCreatedInstance.current.push({
                                        id_instance: creatingInstance.id_instance,
                                        callback: callbackWhenCreated
                                    });
                                }

                                setCreatingInstance(creatingInstance);
                                resolve(creatingInstance);
                            }
                        });
                    })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        })
    };

    /**
     * Permite abortar el proceso para crear una nueva instancia
     * @returns Promesa
     */
    const AbortCreateInstance = () => {
        return new Promise<void>((resolve, reject) => {
            try {
                getConnection()
                    .then((connection) => {
                        // Abortar creación de instancia
                        connection.emit("create-instance-abort", (err?: string) => {
                            if (err) reject(new Error(err));
                            else resolve();
                            setCreatingInstance(null);
                        });
                    })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        })
    };

    /**
     * Permite eliminar una instancia
     * @param id_instance Identificador de la instancia a eliminar
     */
    const RemoveInstance = (id_instance: string) => {
        return new Promise<void>((resolve, reject) => {
            try {
                getConnection()
                    .then((connection) => {
                        // Abortar creación de instancia
                        connection.emit("remove-instance", id_instance, (err?: string) => {
                            if (err) reject(new Error(err));
                            else {
                                setInstances((prevInstances) => {
                                    return prevInstances.filter(instance => instance.id_instance !== id_instance);
                                });

                                resolve();
                            }
                        });
                    })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Permite actualizar una instancia
     * @param id_instance Identificador de la instancia
     * @param instanceChanges Cambios a realizar en la instancia
     * @returns Promesa
     */
    const UpdateInstance = (id_instance: string, instanceChanges: { [key in keyof IInstance]?: IInstance[key] }) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/instance/settings", environment.server).href;

                    // Actualizar registro de una instancia en particular
                    setInstances(instances => {
                        return instances.map(instanceItem => {
                            return instanceItem.id_instance !== id_instance ? instanceItem : {
                                ...instanceItem,
                                ...instanceChanges
                            };
                        });
                    });

                    // Actualizar registros
                    axios.post(urlRequest, instanceChanges, {
                        headers: {
                            Authorization: instance.token
                        }
                    })
                        .then((res) => {
                            console.log("Updated!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Permite Inicializar una instancia
     * @param id_instance Identificador de la instancia
     */
    const StartInstance = (id_instance: string) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/instance/start", environment.server).href;

                    // Actualizar registro de una instancia en particular
                    // setInstances(instances => {
                    //     return instances.map(instanceItem => {
                    //         return instanceItem.id_instance !== id_instance ? instanceItem : {
                    //             ...instanceItem,
                    //             status: "waiting",
                    //             progress: 0,
                    //             qrcode: null
                    //         };
                    //     });
                    // });

                    // Actualizar registros
                    axios.post(urlRequest, {}, {
                        headers: {
                            Authorization: instance.token
                        }
                    })
                        .then((res) => {
                            console.log("Updating!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                // Rechazar promesa
                reject(err);
            }
        });
    };

    /**
     * Permite detener la instancia (no la elimina), pero si olvida los datos de authenticación. Por lo que será
     * necesario volver a vincular el código qr
     * @param id_instance Identificador de la instancia
     */
    const LogoutInstance = (id_instance: string) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/instance/logout", environment.server).href;

                    // Actualizar registro de una instancia en particular
                    // setInstances(instances => {
                    //     return instances.map(instanceItem => {
                    //         return instanceItem.id_instance !== id_instance ? instanceItem : {
                    //             ...instanceItem,
                    //             status: "waiting",
                    //             progress: 0,
                    //             qrcode: null
                    //         };
                    //     });
                    // });

                    // Actualizar registros
                    axios.post(urlRequest, {}, {
                        headers: {
                            Authorization: instance.token
                        }
                    })
                        .then((res) => {
                            console.log("Logout!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                // Rechazar promesa
                reject(err);
            }
        });
    };

    /**
     * Detiene la instancia, de manera que se puedan liberar recursos en el servidor. (No se elimina la instancia y
     * no se elimina los datos de vinculación, por lo que la proxima vez que se inicie, no será necesario vincular
     * nuevamente el código qr)
     * @param id_instance Identificador de la instancia
     */
    const StopInstance = (id_instance: string) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/instance/stop", environment.server).href;

                    // Actualizar registro de una instancia en particular
                    // setInstances(instances => {
                    //     return instances.map(instanceItem => {
                    //         return instanceItem.id_instance !== id_instance ? instanceItem : {
                    //             ...instanceItem,
                    //             status: "waiting",
                    //             progress: 0,
                    //             qrcode: null
                    //         };
                    //     });
                    // });

                    // Actualizar registros
                    axios.post(urlRequest, {}, {
                        headers: {
                            Authorization: instance.token
                        }
                    })
                        .then((res) => {
                            console.log("Stoped!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                // Rechazar promesa
                reject(err);
            }
        });
    };

    /**
     * Actualiza el token de authorización de una instancia
     * @param id_instance Identificador de la instancia
     * @returns Token de authorización
     */
    const ChangeTokenInstance = (id_instance: string) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/instance/token", environment.server).href;

                    // Actualizar registro de una instancia en particular
                    // setInstances(instances => {
                    //     return instances.map(instanceItem => {
                    //         return instanceItem.id_instance !== id_instance ? instanceItem : {
                    //             ...instanceItem,
                    //             status: "waiting",
                    //             progress: 0,
                    //             qrcode: null
                    //         };
                    //     });
                    // });

                    // Actualizar registros
                    axios.post(urlRequest, {}, {
                        headers: {
                            Authorization: instance.token
                        }
                    })
                        .then((res) => {
                            console.log("Stoped!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                // Rechazar promesa
                reject(err);
            }
        });
    };

    const ClearMessagesByStatus = (id_instance: string, status: Itm_messages["status"]) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/messages/clear", environment.server).href;

                    // Actualizar registros
                    axios.post(urlRequest, { status }, {
                        headers: {
                            Authorization: instance.token
                        }
                    })
                        .then((res) => {
                            console.log("Removed messages!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                reject(err);
            }
        });
    }
    const RetryMessagesByStatus = (id_instance: string, status: Itm_messages["status"]) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/messages/retry", environment.server).href;

                    // Actualizar registros
                    axios.post(urlRequest, { status }, {
                        headers: {
                            Authorization: instance.token
                        }
                    })
                        .then((res) => {
                            console.log("Retried messages!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                reject(err);
            }
        });
    }

    const SendMassive = (id_instance: string, massive: IMassiveRequest, config?: IConfigMassive) => {
        return new Promise<void>((resolve, reject) => {
            try {
                const instance = instances.find(instanceItem => instanceItem.id_instance === id_instance);

                if (instance) {
                    const urlRequest = new URL("/messages/massive", environment.server).href;
                    const formData = new FormData();

                    formData.append("type", massive.type);
                    formData.append("data", JSON.stringify(massive.data));
                    formData.append("content", massive.content);
                    formData.append("name", massive.name);
                    formData.append("description", massive.description);

                    if (massive.media) {
                        const blob = new Blob([massive.media.buff], { type: massive.media.mimetype });
                        const file = new File([blob], massive.media.filename, { type: massive.media.mimetype });
                        formData.append("media", file);
                    }

                    // Actualizar registros
                    axios.post(urlRequest, formData, {
                        headers: {
                            Authorization: instance.token
                        },
                        onUploadProgress: config?.onUploading && ((ev) => {
                            if (typeof ev.progress === "number") {
                                config.onUploading(ev.progress);
                            }
                        }),
                        signal: config?.signal
                    })
                        .then((res) => {
                            console.log("Masive sended!:", res.data);
                            resolve();
                        })
                        .catch(reject);

                }
                else throw new Error("Instance '" + id_instance + "' no found");
            }
            catch (err) {
                reject(err);
            }
        });
    }

    return (
        <userContext.Provider value={{
            SendMassive,
            connected: !!__connectionRef.current?.connected,
            authState,
            user,
            ClearMessagesByStatus,
            RetryMessagesByStatus,
            creatingInstance,
            instances,
            SignInWithGoogle,
            SignIn,
            SignUp,
            SignUpAuthorize,
            Logout,
            CreateInstance,
            AbortCreateInstance,
            RemoveInstance,
            UpdateInstance,
            StartInstance,
            StopInstance,
            LogoutInstance,
            ChangeTokenInstance
        }}>
            {children}
        </userContext.Provider>
    )
}