import { Grid, useTheme } from '@mui/material';
import React, { FC, ReactNode } from "react";
import { Outlet } from 'react-router-dom';

// import Header from '../Header';
import AuthGuard from 'components/authentication/AuthGuard';
// import { userContext } from 'contexts/user';
import DashboardLayout from '../DashboardLayout';
import SidebarMenuAPI from './SidebarMenuAPI';
import SidebarMenuWS from './SidebarMenuWS';

interface SidebarLayoutProps {
  type: "websocket" | "api"
  children?: ReactNode;
}

function IsAuthorized(props: { children: React.ReactNode, other: React.ReactNode }) {
  // const userAccount = React.useContext(userContext);
  // const authorized = userAccount.isAuthorized;

  // if(authorized) {
  return (
    <AuthGuard>
      <DashboardLayout otherSidebar={props.other}>
        {props.children}
      </DashboardLayout>
    </AuthGuard>
  )
  // }
  // return <>{props.children}</>
}

const SidebarLayout: FC<SidebarLayoutProps> = (props) => {
  const theme = useTheme();

  return (
    <IsAuthorized other={props.type === "api" ? <SidebarMenuAPI /> : <SidebarMenuWS />}>
      <Grid container>
        <Outlet />
      </Grid>
    </IsAuthorized>
  );
};

export default SidebarLayout;
