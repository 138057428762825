import BaseMenu from "./baseMenu";


export default function ChatsMenu() {
    return <BaseMenu items={[
        {
            to: "/docs/api/chats/get/chats",
            type: "get",
            display: "CHATS"
        },
        {
            to: "/docs/api/chats/get/ids",
            type: "get",
            display: "IDS"
        },
        {
            to: "/docs/api/chats/get/messages",
            type: "get",
            display: "MESSAGES"
        },
        {
            to: "/docs/api/chats/post/archive",
            type: "post",
            display: "ARCHIVE"
        },
        {
            to: "/docs/api/chats/post/unarchive",
            type: "post",
            display: "UNARCHIVE"
        },
        {
            to: "/docs/api/chats/post/clearmessages",
            type: "post",
            display: "CLEARMESSAGES"
        },
        {
            to: "/docs/api/chats/post/delete",
            type: "post",
            display: "DELETE"
        },
        {
            to: "/docs/api/chats/post/read",
            type: "post",
            display: "READ"
        }
      ]}
    />
}