import BaseMenu from "./baseMenu";


export default function InstanceMenu() {
    return <BaseMenu items={[
        {
            to: "/docs/api/instance/get/status",
            type: "get",
            display: "STATUS"
        },
        {
            to: "/docs/api/instance/get/qr",
            type: "get",
            display: "QR"
        },
        {
            to: "/docs/api/instance/get/qrcode",
            type: "get",
            display: "QRCODE"
        },
        {
            to: "/docs/api/instance/get/me",
            type: "get",
            display: "ME"
        },
        {
            to: "/docs/api/instance/get/settings",
            type: "get",
            display: "SETTINGS"
        },
        {
            to: "/docs/api/instance/post/start",
            type: "post",
            display: "START"
        },
        {
            to: "/docs/api/instance/post/stop",
            type: "post",
            display: "STOP"
        },
        {
            to: "/docs/api/instance/post/logout",
            type: "post",
            display: "LOGOUT"
        },
        {
            to: "/docs/api/instance/post/restart",
            type: "post",
            display: "RESTART"
        },
        {
            to: "/docs/api/instance/post/settings",
            type: "post",
            display: "SETTINGS"
        },
        // {
        //     to: "/docs/api/instance/post/clear",
        //     type: "post",
        //     display: "CLEAR"
        // },
        // {
        //     to: "/docs/api/instance/post/delete",
        //     type: "post",
        //     display: "DELETE"
        // }
    ]}
    />
}