import { Backdrop, CircularProgress } from "@mui/material";
import DashboardLayout from "components/Layouts/DashboardLayout";
import SidebarLayoutBot from "components/Layouts/bot";
import SidebarMenu from "components/Layouts/docs";
import LoadingScreen from "components/LoadingScreen";
import useUser from "hooks/useUser.hook";
import { FC, LazyExoticComponent, ReactElement, Suspense, lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const ShowInstanceLayout = Loadable(lazy(() => import("./pages/dashboards/instances/show/layout")))
const MasivosLayout = Loadable(lazy(() => import("./pages/dashboards/instances/show/masivos/layout")))

// Home Page
const Home = Loadable(lazy(() => import("./pages/home")))

// authentication pages
const Login = Loadable(lazy(() => import("./pages/login")));
const Register = Loadable(lazy(() => import("./pages/register")));
const ForgetPassword = Loadable(lazy(() => import("./pages/forget-password")));

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/statistics")));

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/dashboards/user-profile")));

// user instances management
const UserInstances = Loadable(lazy(() => import("./pages/dashboards/instances")));
const AddInstance = Loadable(lazy(() => import("./pages/dashboards/instances/add")));
const AdminInstance = Loadable(lazy(() => import("./pages/dashboards/instances/show")));
const AdminBotInstance = Loadable(lazy(() => import("./pages/dashboards/instances/show/bot")));
const CommandsBotInstance = Loadable(lazy(() => import("./pages/dashboards/instances/show/bot/commands")));
const SchedulesBotInstance = Loadable(lazy(() => import("./pages/dashboards/instances/show/bot/schedules")));
const MessagesInstance = Loadable(lazy(() => import("./pages/dashboards/instances/show/messages")));
const ExcelMasivos = Loadable(lazy(() => import("./pages/dashboards/instances/show/masivos")));
const BlackList = Loadable(lazy(() => import("./pages/dashboards/instances/show/blacklist")));
// const ExportContact = Loadable(lazy(() => import("./pages/dashboards/instances/show/export")));
const Pay = Loadable(lazy(() => import("./pages/pays/pays")));

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// documents
const Document = {
  Messages: {
    Get: {
      Text: Loadable(
        lazy(() => import('./pages/docs/API/messages/text'))
      ),
      Contact: Loadable(
        lazy(() => import("./pages/docs/API/messages/contact"))
      ),
      Image: Loadable(
        lazy(() => import('./pages/docs/API/messages/image'))
      ),
      Sticker: Loadable(
        lazy(() => import('./pages/docs/API/messages/sticker'))
      ),
      Document: Loadable(
        lazy(() => import('./pages/docs/API/messages/document'))
      ),
      Audio: Loadable(
        lazy(() => import('./pages/docs/API/messages/audio'))
      ),
      Voice: Loadable(
        lazy(() => import('./pages/docs/API/messages/voice'))
      ),
      Video: Loadable(
        lazy(() => import('./pages/docs/API/messages/video'))
      ),
    },
    Post: {
      Messages: Loadable(
        lazy(() => import('./pages/docs/API/messages/messages'))
      ),
      Statistics: Loadable(
        lazy(() => import('./pages/docs/API/messages/statistics'))
      )
    }
  },
  Instance: {
    Get: {
      Status: Loadable(
        lazy(() => import('./pages/docs/API/instance/get/status'))
      ),
      Qr: Loadable(
        lazy(() => import('./pages/docs/API/instance/get/qr'))
      ),
      Qrcode: Loadable(
        lazy(() => import('./pages/docs/API/instance/get/qrcode'))
      ),
      Me: Loadable(
        lazy(() => import('./pages/docs/API/instance/get/me'))
      ),
      Setting: Loadable(
        lazy(() => import('./pages/docs/API/instance/get/settings'))
      ),
    },
    Post: {
      Start: Loadable(
        lazy(() => import("./pages/docs/API/instance/post/start"))
      ),
      Stop: Loadable(
        lazy(() => import("./pages/docs/API/instance/post/stop"))
      ),
      Logout: Loadable(
        lazy(() => import('./pages/docs/API/instance/post/logout'))
      ),
      Restart: Loadable(
        lazy(() => import('./pages/docs/API/instance/post/restart'))
      ),
      Setting: Loadable(
        lazy(() => import('./pages/docs/API/instance/post/settings'))
      ),
      Clear: Loadable(
        lazy(() => import('./pages/docs/API/instance/post/clear'))
      ),
      Delete: Loadable(
        lazy(() => import("./pages/docs/API/instance/post/delete"))
      )
    }
  },
  Chats: {
    Get: {
      Chats: Loadable(
        lazy(() => import('./pages/docs/API/chats/get/chats'))
      ),
      Ids: Loadable(
        lazy(() => import('./pages/docs/API/chats/get/ids'))
      ),
      Messages: Loadable(
        lazy(() => import('./pages/docs/API/chats/get/messages'))
      ),
    },
    Post: {
      Archive: Loadable(
        lazy(() => import('./pages/docs/API/chats/post/archive'))
      ),
      Unarchive: Loadable(
        lazy(() => import('./pages/docs/API/chats/post/unarchive'))
      ),
      Clearmessages: Loadable(
        lazy(() => import('./pages/docs/API/chats/post/clearmessages'))
      ),
      Delete: Loadable(
        lazy(() => import('./pages/docs/API/chats/post/delete'))
      ),
      Read: Loadable(
        lazy(() => import('./pages/docs/API/chats/post/read'))
      ),
    }
  },
  Contacts: {
    Get: {
      Contacts: Loadable(
        lazy(() => import('./pages/docs/API/contacts/get/contacts'))
      ),
      Ids: Loadable(
        lazy(() => import('./pages/docs/API/contacts/get/ids'))
      ),
      Contact: Loadable(
        lazy(() => import('./pages/docs/API/contacts/get/contact'))
      ),
      Blocked: Loadable(
        lazy(() => import('./pages/docs/API/contacts/get/blocked'))
      ),
      Invalid: Loadable(
        lazy(() => import('./pages/docs/API/contacts/get/invalid'))
      ),
      Check: Loadable(
        lazy(() => import('./pages/docs/API/contacts/get/check'))
      ),
      Image: Loadable(
        lazy(() => import('./pages/docs/API/contacts/get/image'))
      ),
    },
    Post: {
      Block: Loadable(
        lazy(() => import('./pages/docs/API/contacts/post/block'))
      ),
      Unblock: Loadable(
        lazy(() => import('./pages/docs/API/contacts/post/unblock'))
      ),
    }
  },
  Groups: {
    Get: {
      Groups: Loadable(
        lazy(() => import('./pages/docs/API/groups/get/groups'))
      ),
      Ids: Loadable(
        lazy(() => import('./pages/docs/API/groups/get/ids'))
      ),
      Group: Loadable(
        lazy(() => import('./pages/docs/API/groups/get/group'))
      ),
    }
  },
  Media: {
    Post: {
      Upload: Loadable(
        lazy(() => import('./pages/docs/API/media/post/upload'))
      ),
      Delete: Loadable(
        lazy(() => import('./pages/docs/API/media/post/delete'))
      ),
      Deletebydate: Loadable(
        lazy(() => import('./pages/docs/API/media/post/deletebydate'))
      ),
    }
  }
}

const DocumentWs = {
  Messages: {
    Chat: Loadable(lazy(() => import("./pages/docs/WS/received/messages/chat"))),
    Image: Loadable(lazy(() => import("./pages/docs/WS/received/messages/image"))),
    Sticker: Loadable(lazy(() => import("./pages/docs/WS/received/messages/sticker"))),
    Document: Loadable(lazy(() => import("./pages/docs/WS/received/messages/document"))),
    Audio: Loadable(lazy(() => import("./pages/docs/WS/received/messages/audio"))),
    Voice: Loadable(lazy(() => import("./pages/docs/WS/received/messages/voice"))),
    Video: Loadable(lazy(() => import("./pages/docs/WS/received/messages/video"))),
  }
}

function IsAuthorized(props: { authorized: React.ReactNode, unauthorized: React.ReactNode }) {
  const userAccount = useUser();
  let element: ReactElement = <></>;

  switch (userAccount.authState) {
    case "authorized":
      element = <>{props.authorized}</>;
      break;

    case "unauthorized":
      element = <>{props.unauthorized}</>;
      break;
  }

  return (
    <>
      {element}
      <Backdrop
        sx={{ width: "100%", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000000 }}
        open={userAccount.authState == "checking"}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

// routes
const routes = [
  {
    path: "home",
    element: <Home />
  },
  {
    path: "/",
    element: (
      <IsAuthorized
        authorized={
          <Navigate to="/dashboard" />
        }
        unauthorized={
          <Navigate to="/home" />
        }
      />
    )
  },
  {
    path: "login",
    element: (
      <IsAuthorized
        authorized={<Navigate to="/dashboard" />}
        unauthorized={<Login />}
      />
    ),
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "dashboard",
    element: (
      <Outlet />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="statistics" />
      },
      {
        path: "instances/show/:id_instance/bot",
        element: <SidebarLayoutBot />,
        children: [
          {
            element: <ShowInstanceLayout />,
            children: [
              {
                path: "",
                element: <AdminBotInstance />
              },
              {
                path: "commands",
                element: <CommandsBotInstance />
              },
              {
                path: "schedules",
                element: <SchedulesBotInstance />
              }
            ]
          }
        ]
      },
      {
        element: <DashboardLayout />,
        children: [
          {
            path: "instances",
            children: [
              {
                path: "",
                element: <UserInstances />
              },
              {
                path: "add",
                element: <AddInstance />,
              },
              {
                path: "show/:id_instance",
                element: <ShowInstanceLayout />,
                children: [
                  {
                    path: "",
                    element: <AdminInstance />
                  },
                  {
                    path: "blacklist",
                    element: <BlackList />
                  },
                  {
                    path: "masivos",
                    element: <MasivosLayout />,
                    children: [
                      {
                        path: "",
                        element: <ExcelMasivos />
                      }
                    ]
                  },
                  {
                    path: "messages",
                    element: <MessagesInstance />
                  },
                ]
              }
            ]
          },
          {
            path: "pay",
            element: <Pay />
          },
          {
            path: "statistics",
            element: <DashboardSaaS />,
          },
          {
            path: "user-profile",
            element: <UserProfile />,
          },
        ]
      }
    ]
  },
  {
    path: 'docs',
    children: [
      {
        path: "",
        element: <Navigate to="api" />
      },
      {
        path: "api",
        element: <SidebarMenu type="api" />,
        children: [
          {
            path: '',
            element: <Navigate to="messages" replace />
          },
          {
            path: 'messages',
            children: [
              {
                path: '',
                element: <Navigate to="get" replace />
              },
              {
                path: "get",
                children: [
                  {
                    path: '',
                    element: <Navigate to="text" replace />
                  },
                  {
                    path: 'text',
                    element: <Document.Messages.Get.Text />
                  },
                  {
                    path: 'contact',
                    element: <Document.Messages.Get.Contact />
                  },
                  {
                    path: 'image',
                    element: <Document.Messages.Get.Image />
                  },
                  {
                    path: 'sticker',
                    element: <Document.Messages.Get.Sticker />
                  },
                  {
                    path: 'document',
                    element: <Document.Messages.Get.Document />
                  },
                  {
                    path: 'audio',
                    element: <Document.Messages.Get.Audio />
                  },
                  {
                    path: 'voice',
                    element: <Document.Messages.Get.Voice />
                  },
                  {
                    path: 'video',
                    element: <Document.Messages.Get.Video />
                  },
                ]
              },
              {
                path: "post",
                children: [
                  {
                    path: '',
                    element: <Navigate to="messages" replace />
                  },
                  {
                    path: 'messages',
                    element: <Document.Messages.Post.Messages />
                  },
                  {
                    path: 'statistics',
                    element: <Document.Messages.Post.Statistics />
                  }
                ]
              }
            ]
          },
          {
            path: 'instance',
            children: [
              {
                path: '',
                element: <Navigate to="get" replace />
              },
              {
                path: "get",
                children: [
                  {
                    path: '',
                    element: <Navigate to="status" replace />
                  },
                  {
                    path: "status",
                    element: <Document.Instance.Get.Status />
                  },
                  {
                    path: "qr",
                    element: <Document.Instance.Get.Qr />
                  },
                  {
                    path: "qrcode",
                    element: <Document.Instance.Get.Qrcode />
                  },
                  {
                    path: "me",
                    element: <Document.Instance.Get.Me />
                  },
                  {
                    path: "settings",
                    element: <Document.Instance.Get.Setting />
                  }
                ]
              },
              {
                path: "post",
                children: [
                  {
                    path: '',
                    element: <Navigate to="logout" replace />
                  },
                  {
                    path: "logout",
                    element: <Document.Instance.Post.Logout />
                  },
                  {
                    path: "restart",
                    element: <Document.Instance.Post.Restart />
                  },
                  {
                    path: "settings",
                    element: <Document.Instance.Post.Setting />
                  },
                  {
                    path: "clear",
                    element: <Document.Instance.Post.Clear />
                  },
                  {
                    path: "start",
                    element: <Document.Instance.Post.Start />
                  },
                  {
                    path: "stop",
                    element: <Document.Instance.Post.Stop />
                  },
                  {
                    path: "delete",
                    element: <Document.Instance.Post.Delete />
                  }
                ]
              }
            ]
          },
          {
            path: "chats",
            children: [
              {
                path: '',
                element: <Navigate to="get" replace />
              },
              {
                path: "get",
                children: [
                  {
                    path: '',
                    element: <Navigate to="chats" replace />
                  },
                  {
                    path: "chats",
                    element: <Document.Chats.Get.Chats />
                  },
                  {
                    path: "ids",
                    element: <Document.Chats.Get.Ids />
                  },
                  {
                    path: "messages",
                    element: <Document.Chats.Get.Messages />
                  },
                ]
              },
              {
                path: "post",
                children: [
                  {
                    path: '',
                    element: <Navigate to="archive" replace />
                  },
                  {
                    path: "archive",
                    element: <Document.Chats.Post.Archive />
                  },
                  {
                    path: "unarchive",
                    element: <Document.Chats.Post.Unarchive />
                  },
                  {
                    path: "clearmessages",
                    element: <Document.Chats.Post.Clearmessages />
                  },
                  {
                    path: "delete",
                    element: <Document.Chats.Post.Delete />
                  },
                  {
                    path: "read",
                    element: <Document.Chats.Post.Read />
                  }
                ]
              }
            ]
          },
          {
            path: "contacts",
            children: [
              {
                path: '',
                element: <Navigate to="post" replace />
              },
              {
                path: "post",
                children: [
                  {
                    path: '',
                    element: <Navigate to="block" replace />
                  },
                  {
                    path: "block",
                    element: <Document.Contacts.Post.Block />
                  },
                  {
                    path: "unblock",
                    element: <Document.Contacts.Post.Unblock />
                  },
                ]
              },
              {
                path: "get",
                children: [
                  {
                    path: '',
                    element: <Navigate to="contacts" replace />
                  },
                  {
                    path: "contacts",
                    element: <Document.Contacts.Get.Contacts />
                  },
                  {
                    path: "ids",
                    element: <Document.Contacts.Get.Ids />
                  },
                  {
                    path: "contact",
                    element: <Document.Contacts.Get.Contact />
                  },
                  {
                    path: "blocked",
                    element: <Document.Contacts.Get.Blocked />
                  },
                  {
                    path: "invalid",
                    element: <Document.Contacts.Get.Invalid />
                  },
                  {
                    path: "check",
                    element: <Document.Contacts.Get.Check />
                  },
                  {
                    path: "image",
                    element: <Document.Contacts.Get.Image />
                  },
                ]
              }
            ]
          },
          {
            path: "groups",
            children: [
              {
                path: '',
                element: <Navigate to="get" replace />
              },
              {
                path: "get",
                children: [
                  {
                    path: '',
                    element: <Navigate to="groups" replace />
                  },
                  {
                    path: "groups",
                    element: <Document.Groups.Get.Groups />
                  },
                  {
                    path: "ids",
                    element: <Document.Groups.Get.Ids />
                  },
                  {
                    path: "group",
                    element: <Document.Groups.Get.Group />
                  },
                ]
              }
            ]
          },
          {
            path: "media",
            children: [
              {
                path: '',
                element: <Navigate to="post" replace />
              },
              {
                path: 'post',
                children: [
                  {
                    path: '',
                    element: <Navigate to="upload" replace />
                  },
                  {
                    path: "upload",
                    element: <Document.Media.Post.Upload />
                  },
                  {
                    path: "delete",
                    element: <Document.Media.Post.Delete />
                  },
                  {
                    path: "deletebydate",
                    element: <Document.Media.Post.Deletebydate />
                  },
                ]
              },

            ]
          }
        ]
      },
      {
        path: "ws",
        element: <SidebarMenu type="websocket" />,
        children: [
          {
            path: "",
            element: <Navigate to="received" />
          },
          {
            path: "received",
            children: [
              {
                path: "",
                element: <Navigate to="messages" />
              },
              {
                path: "messages",
                children: [
                  {
                    path: "",
                    element: <Navigate to="chat" />
                  },
                  {
                    path: "chat",
                    element: <DocumentWs.Messages.Chat />
                  },
                  {
                    path: "image",
                    element: <DocumentWs.Messages.Image />
                  },
                  {
                    path: "sticker",
                    element: <DocumentWs.Messages.Sticker />
                  },
                  {
                    path: "document",
                    element: <DocumentWs.Messages.Document />
                  },
                  {
                    path: "audio",
                    element: <DocumentWs.Messages.Audio />
                  },
                  {
                    path: "voice",
                    element: <DocumentWs.Messages.Voice />
                  },
                  {
                    path: "video",
                    element: <DocumentWs.Messages.Video />
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
